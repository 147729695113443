import { createReducer, on } from "@ngrx/store";
import { Deal } from "../../shared/models/deal.model";
import { clearDeals, deleteDeal, deleteDealFail, deleteDealSuccess, editDeal, editDealFail, editDealSuccess, loadDealDetails, loadDealDetailsFail, loadDealDetailsSuccess, loadDeals, loadDealsFail, loadDealsSuccess, setDealsQuery } from "./deals.actions";
import { DefaultGenericList, GenericList } from "../shared/models/generic-list.model";
import { HttpError } from "../shared/models/http-error.model";

export interface DealsState {
  initialized?: boolean;
  loading?: boolean;
  list?: GenericList<Deal>;
  entities?: {[code: string]: Deal};
  detailsError?: HttpError;
}

const initialState: DealsState = {
  initialized: false,
  loading: false,
  list: DefaultGenericList<Deal>(),
  detailsError: undefined,
  entities: {}
};

export const dealsReducer = createReducer(
  initialState,

  on(loadDeals, (state) => ({
    ...state,
    loading: !state.initialized
  })),
  on(loadDealsFail, (state) => ({
    ...state,
    initialized: true,
    loading: false,
  })),
  on(loadDealsSuccess, (state, action) => ({
    ...state,
    initialized: true,
    loading: false,
    list: {
      ...state.list,
      items: action.payload.deals
    }
  })),
  on(setDealsQuery, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      query: action.payload.query
    }
  })),
  on(loadDealDetails, (state) => ({ ...state, loading: true })),
  on(loadDealDetailsFail, (state, action) => ({ ...state, loading: false, detailsError: action.payload.error })),
  on(loadDealDetailsSuccess, (state, action) => ({
    ...state,
    loading: false,
    detailsError: undefined,
    list: {
      ...state.list,
      items: state.list.items.map(item => {
        if (item.id == action.payload.deal.id) {
          return action.payload.deal;
        }
        return item;
      })
    },
    entities: {
      ...state.entities,
      [action.payload.deal.code]: action.payload.deal
    }
  })),
  on(editDeal, (state, action) => ({
    ...state,
    loading: true
  })),
  on(editDealFail, (state) => ({
    ...state,
    loading: false
  })),
  on(editDealSuccess, (state, action) => ({
    ...state,
    loading: false,
    entities: {
      ...state.entities,
      [action.payload.deal.code]: action.payload.deal
    }
  })),
  on(deleteDeal, (state) => ({
    ...state,
    loading: true
  })),
  on(deleteDealFail, (state) => ({
    ...state,
    loading: false
  })),
  on(deleteDealSuccess, (state, action) => ({
    ...state,
    loading: false,
    list: {
      ...state.list,
      items: state.list.items.filter(i => i.code != action.payload.deal.code),
    },
    entities: {
      ...state.entities,
      [action.payload.deal.code]: undefined
    }
  })),
  on(clearDeals, () => initialState)
);
