import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID  } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { BaseStoreModule } from './store/store.module';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
registerLocaleData(es);

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'access', loadChildren: () => import('./modules/access/access.module').then(m => m.AccessModule) },
]

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),

    HttpClientModule,

    SocketIoModule.forRoot({
      url: environment.notifierApiUrl
    }),
    NgxCurrencyModule.forRoot({
      align: 'right',
      allowNegative: false,
      allowZero: true,
      decimal: ',',
      precision: 0,
      prefix: '€ ',
      suffix: '',
      thousands: '.',
      nullable: true,
      inputMode: CurrencyMaskInputMode.NATURAL,
    }),

    BaseStoreModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-ES'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
