import { Selector } from "@ngrx/store";
import { DealsState } from "./deals/deals.reducer";
import { InvestorsState } from "./investors/investors.reducer";
import { PortfoliosState } from "./portfolios/portfolios.reducer";
import { AuthState } from "./auth/auth.reducer";

export interface BaseState {
  deals: DealsState;
  portfolios: PortfoliosState,
  investors: InvestorsState;
  auth: AuthState;
};

export const getBaseState: Selector<BaseState, BaseState> = state => state;
