import { createAction } from "@ngrx/store";
import { Deal } from "../../shared/models/deal.model";
import { payload } from "../shared/utils/ngrx-creators";

export const initPortfolios = createAction('[Portfolios] Init Portfolios');

export const loadPortfolios = createAction('[Portfolios] Load Portfolios');

export const loadPortfoliosFail = createAction('[Portfolios] Load Portfolios Fail');

export const loadPortfoliosSuccess = createAction(
  '[Portfolios] Load Portfolios Success',
  payload<{ portfolios: Deal[] }>()
);

export const loadPortfolioDetails = createAction(
  '[Portfolios] Load Portfolios Details',
  payload<{ code: string }>()
);

export const loadPortfolioDetailsFail = createAction('[Portfolios] Load Portfolio Details Fail');

export const loadPortfolioDetailsSuccess = createAction(
  '[Portfolios] Load Portfolio Details Success',
  payload<{ portfolio: Deal }>()
);
