import { createReducer, on } from "@ngrx/store";
import { Deal } from "../../shared/models/deal.model";
import { DefaultGenericList, GenericList } from "../shared/models/generic-list.model";
import { HttpError } from "../shared/models/http-error.model";

export interface AuthState {
}

const initialState: AuthState = {
};

export const authReducer = createReducer(
  initialState,
);
