import { createAction } from "@ngrx/store";
import { Company } from "../../shared/models/company.model";
import { CompanyInvestorDto, CompanyInvestorStatsDto } from "../../shared/models/dtos/company-investor.dto";
import { User } from "../../shared/models/user.model";
import { httpError, payload } from "../shared/utils/ngrx-creators";
import {
  UpdateBusinessConsultantDto
} from '../../shared/dtos/update-business-consultant.dto';

export const initInvestors = createAction('[Investors] Init Investors');

export const loadInvestors = createAction('[Investors] Load Investors');

export const loadInvestorsFail = createAction('[Investors] Load Investors Fail');

export const loadInvestorsSuccess = createAction(
  '[Investor] Load Investors Success',
  payload<{ list: CompanyInvestorDto[] }>()
);

export const setInvestorsQuery = createAction(
  '[Investors] Set Investors Query',
  payload<{ query: string }>()
);

export const setInvestorsBusinessConsultantFilter = createAction(
  '[Investors] Set Investors Business Consultant Filter',
  payload<{ userId: number }>()
);

export const setInvestorItemActive = createAction(
  '[Investors] Set Investors Item Active',
  payload<{ id: number, isActive: boolean }>()
);

export const setInvestorItemActiveFail = createAction(
  '[Investors] Set Investors Item Active Fail'
);

export const setInvestorItemActiveSuccess = createAction(
  '[Investors] Set Investors Item Active Success',
  payload<{ id: number, isActive: boolean }>()
);

export const setInvestorItemBusinessConsultant = createAction(
  '[Investors] Set Investor Item Business Consultant',
  payload<{ dto: UpdateBusinessConsultantDto }>()
);

export const setInvestorItemBusinessConsultantFail = createAction(
  '[Investors] Set Investor Item Business Consultant Fail',
);

export const setInvestorItemBusinessConsultantSuccess = createAction(
  '[Investors] Set Investor Item Business Consultant Success',
  payload<{ company: Company }>()
);

export const loadInvestorCompanyStats = createAction(
  '[Investors] Load Investor Company Stats',
  payload<{ id: number }>()
);

export const loadInvestorCompanyStatsFail = createAction(
  '[Investors] Load Investor Company Stats Fail', httpError()
);
export const loadInvestorCompanyStatsSuccess = createAction(
  '[Investors] Load Investor Company Stats Success',
  payload<{ stats: CompanyInvestorStatsDto }>()
);

export const loadInvestorCompany = createAction(
  '[Investors] Load Investor Company',
  payload<{ id: number }>()
);

export const loadInvestorCompanyFail = createAction('[Investors] Load Investor Company Fail');

export const loadInvestorCompanySuccess = createAction(
  '[Investor] Load Investor Company Success',
  payload<{ company: Company }>()
);

export const loadInvestorUser = createAction(
  '[Investors] Load Investor User',
  payload<{ id: number }>()
);

export const loadInvestorUserFail = createAction('[Investors] Load Investor User Fail');

export const loadInvestorUserSuccess = createAction(
  '[Investor] Load Investor User Success',
  payload<{ user: User }>()
);

export const clearInvestors = createAction('[Investors] Clear Investors');
