import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export class BaseService {

  protected apiUrl: string = environment.apiUrl;
  // protected headers = new Headers({'Content-Type': 'application/json'});

  protected TOKEN_NAME: string = 'accessToken';

  constructor(protected http: HttpClient) {}

  getHeaders() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(this.TOKEN_NAME)}`
      }
    };
  }

  protected httpGet<T>(url: string, params: any = null) {
    const paramsValue = params ? `?${Object.keys(params).filter(p => params[p] !== null && params[p] !== undefined).map(p => `${p}=${params[p]}`).join('&')}` : '';
    return this.http.get<T>(`${this.apiUrl}${url}${paramsValue}`, this.getHeaders());
  }

  protected httpPost<T>(url: string, data?: any) {
    return this.http.post<T>(`${this.apiUrl}${url}`, data, this.getHeaders());
  }

  protected httpPut<T>(url: string, data: any) {
    return this.http.put<T>(`${this.apiUrl}${url}`, data, this.getHeaders());
  }

  protected httpPatch<T>(url: string, data?: any) {
    return this.http.patch<T>(`${this.apiUrl}${url}`, data, this.getHeaders());
  }

  protected httpDelete<T>(url: string) {
    return this.http.delete<T>(`${this.apiUrl}${url}`, this.getHeaders());
  }

}
