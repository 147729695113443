import { Injectable } from '@angular/core';
import { BaseService } from './_base.service';
import { Observable } from 'rxjs';
import { Deal } from '../models/deal.model';
import { Property } from '../models/property.model';
import { HttpClient } from '@angular/common/http';

export interface GetPropertiesDto {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  orderMode?: 'ASC' | 'DESC';
  search?: string;
  onlyAvailables?: boolean;
  dealId?: number
}

export interface GetPropertiesResultDto {
  items: Property[];
  totalItems: number;
  filteredItems: number;
}

@Injectable({
  providedIn: 'root'
})
export class DealsService extends BaseService {

  private endpoint = `deals`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  getDeals(): Observable<Deal[]> {
    return this.httpGet<Deal[]>(`${this.endpoint}`);
  }

  getPortfolios(): Observable<Deal[]> {
    return this.httpGet<Deal[]>(`${this.endpoint}/portfolios`);
  }

  getDealDetails(code: string) {
    return this.httpGet<Deal>(`${this.endpoint}/${code}`);
  }

  createDeal(data: any) {
    return this.httpPost<Deal>(`${this.endpoint}`, data);
  }

  createPortfolio(data: any) {
    return this.httpPost<Deal>(`${this.endpoint}/portfolios`, data);
  }

  updateDeal(id: number, data: any) {
    return this.httpPut<Deal>(`${this.endpoint}/${id}`, data);
  }

  addDealImage(id: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(
      `${this.apiUrl}${this.endpoint}/${id}/images`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem(this.TOKEN_NAME)}`
        }
      }
    );
  }

  deleteDealImage(id: number) {
    return this.httpDelete(`${this.endpoint}/images/${id}`);
  }

  addPropertyToDeal(dealId: number, data: any) {
    return this.httpPost<Property>(`${this.endpoint}/${dealId}/properties`, data);
  }

  updateProperty(id: number, data: any) {
    return this.httpPut<Property>(`${this.endpoint}/properties/${id}`, data);
  }

  updatePropertyImage(id: number, file: File, replaceId?: number) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(
      `${this.apiUrl}${this.endpoint}/properties/${id}/images/gallery${replaceId ? '?replaceId=' + replaceId : ''}`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem(this.TOKEN_NAME)}`
        }
      }
    );
  }

  deletePropertyImage(propertyId: number, imageId: number) {
    return this.httpDelete(`${this.endpoint}/properties/${propertyId}/images/gallery/${imageId}`);
  }

  validateProperty(id: number) {
    return this.httpPut(`${this.endpoint}/properties/${id}/validate`, {});
  }
}
