import { createSelector } from "@ngrx/store";
import { getBaseState } from "../store";

const getInvestorsState = createSelector(getBaseState, state => state.investors);

export const getInvestorsInitialized = createSelector(getInvestorsState, state => state.initialized);

export const getInvestorsLoading = createSelector(getInvestorsState, state => state.loading);

export const getInvestorsList = createSelector(getInvestorsState, state => state.list);

export const getInvestorsCompanyStatsList = createSelector(getInvestorsState, state => state.companiesStats);

// export const getInvestorsListItems = createSelector(getInvestorsList, state => state.items);

// export const getInvestorsListQuery = createSelector(getInvestorsList, state => state.query);

export const getInvestorsCompany = (id: number) =>
  createSelector(getInvestorsState, state => state.companiesList.find(c => c.id == id));

export const getInvestorsUser = (id: number) =>
  createSelector(getInvestorsState, state => state.usersList.find(c => c.id == id));

export const getInvestorCompanyStats = (id: number) =>
  createSelector(getInvestorsCompanyStatsList, list => list.find(i => i.companyId == id));
