import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { DealOperationsService } from "../../shared/services/deal-operations.service";
import { mapErrorToAction, mapToPayloadProperty } from "../shared/utils/operators";
import { initPortfolios, loadPortfolioDetails, loadPortfolioDetailsFail, loadPortfolioDetailsSuccess, loadPortfolios, loadPortfoliosFail, loadPortfoliosSuccess } from "./portfolios.actions";
import { getPortfoliosInitialized } from "./portfolios.selectors";

@Injectable()
export class PortfoliosEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: DealOperationsService
  ) {}

  initPortfolios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initPortfolios),
      withLatestFrom(this.store.pipe(select(getPortfoliosInitialized))),
      filter(([_, initialized]) => !initialized),
      map(() => loadPortfolios())
    )
  );

  loadPortfolios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPortfolios),
      mergeMap(() =>
        this.service.getPortfolios().pipe(
          map(portfolios => loadPortfoliosSuccess({ portfolios })),
          mapErrorToAction(loadPortfoliosFail)
        )
      )
    )
  );

  loadPortfolioDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPortfolioDetails),
      mapToPayloadProperty('code'),
      mergeMap((code) =>
        this.service.getDealDetails(code).pipe(
          map(portfolio => loadPortfolioDetailsSuccess({ portfolio })),
          mapErrorToAction(loadPortfolioDetailsFail)
        )
      )
    )
  );
}
