import { createSelector } from "@ngrx/store";
import { getBaseState } from "../store";

const getDealsState = createSelector(getBaseState, state => state.deals);

export const getDealsList = createSelector(getDealsState, state => state.list);

export const getDealsInitialized = createSelector(getDealsState, state => state.initialized);

export const getDealsLoading = createSelector(getDealsState, state => state.loading);

export const getDealDetails = (code: string) => createSelector(getDealsState, state => state.entities[code]);

export const getDealDetailsError = createSelector(getDealsState, state => state.detailsError);
