export const environment = {
  production: true,
  apiUrl: 'https://haya-select-api.dev.stoaproductgroup.cloud/',
  notifierApiUrl: 'https://haya-select-notifier-api.dev.stoaproductgroup.cloud',

  jupiterConfig: {
    isEnabled: true,
    hayaUsersDomain: 'pre-management-select',
    authorizeUri: 'https://adfspre.haya.es/adfs/oauth2/authorize',
    clientId: 'c271038e-52d6-4d6e-9c03-edb86a4fde6b',
    resource: 'http://haya.es/select',
    redirectUri: 'https://pre-management-select.haya.es/access/oauth',
  },

  networkCompanyId: 48,
  networkDealTemplateId: 273
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
