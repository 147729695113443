import { createSelector } from "@ngrx/store";
import { getBaseState } from "../store";

const getPortfoliosState = createSelector(getBaseState, state => state.portfolios);

export const getPortfoliosList = createSelector(getPortfoliosState, state => state.portfoliosList);

export const getPortfoliosInitialized = createSelector(getPortfoliosState, state => state.initialized);

export const getPortfoliosLoading = createSelector(getPortfoliosState, state => state.loading);

export const getPortfolioDetails = (id: string) => createSelector(getPortfoliosState, state => state.entities[id]);
