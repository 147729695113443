import { createAction } from "@ngrx/store";
import { payload } from "../shared/utils/ngrx-creators";

export const showSuccessMessage = createAction(
  '[Messages] Show Success Message',
  payload<{ message?: string }>()
);

export const showErrorMessage = createAction(
  '[Messages] Show Error Message',
  payload<{ message?: string }>()
);
