import { createReducer, on } from "@ngrx/store";
import { Deal } from "../../shared/models/deal.model";
import { loadPortfolioDetails, loadPortfolioDetailsFail, loadPortfolios, loadPortfolioDetailsSuccess, loadPortfoliosFail, loadPortfoliosSuccess } from "./portfolios.actions";

export interface PortfoliosState {
  initialized?: boolean;
  loading?: boolean;
  portfoliosList?: Deal[];
  entities?: {[code: string]: Deal};
}

const initialState: PortfoliosState = {
  initialized: false,
  loading: false,
  portfoliosList: [],
  entities: {}
};

export const portfoliosReducer = createReducer(
  initialState,

  on(loadPortfolios, (state) => ({
    ...state,
    loading: !state.initialized
  })),
  on(loadPortfoliosFail, (state) => ({
    ...state,
    initialized: true,
    loading: false,
    dealsList: []
  })),
  on(loadPortfoliosSuccess, (state, action) => ({
    ...state,
    initialized: true,
    loading: false,
    portfoliosList: action.payload.portfolios
  })),

  on(loadPortfolioDetails, (state) => ({ ...state, loading: true })),
  on(loadPortfolioDetailsFail, (state) => ({ ...state })),
  on(loadPortfolioDetailsSuccess, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      [action.payload.portfolio.code]: action.payload.portfolio
    }
  })),
);
