import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { DealOperationsService } from "../../shared/services/deal-operations.service";
import { mapErrorToAction, mapToPayload, mapToPayloadProperty } from "../shared/utils/operators";
import { deleteDeal, deleteDealFail, deleteDealSuccess, editDeal, editDealFail, editDealSuccess, initDeals, loadDealDetails, loadDealDetailsFail, loadDealDetailsSuccess, loadDeals, loadDealsFail, loadDealsSuccess } from "./deals.actions";
import { getDealsInitialized } from "./deals.selectors";
import { DealsService } from "../../shared/services/deals.service";
import { showSuccessMessage } from "../messages/messages.actions";

@Injectable()
export class DealsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: DealOperationsService,
    private dealsService: DealsService
  ) {}

  initDeals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initDeals),
      withLatestFrom(this.store.pipe(select(getDealsInitialized))),
      filter(([_, initialized]) => !initialized),
      map(() => loadDeals())
    )
  );

  loadDeals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDeals),
      mergeMap(() =>
        this.service.getDeals().pipe(
          map(deals => loadDealsSuccess({ deals })),
          mapErrorToAction(loadDealsFail)
        )
      )
    )
  );

  loadDealDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDealDetails),
      mapToPayloadProperty('code'),
      mergeMap((code) =>
        this.service.getDealDetails(code).pipe(
          map(deal => loadDealDetailsSuccess({ deal })),
          mapErrorToAction(loadDealDetailsFail)
        )
      )
    )
  );

  editDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editDeal),
      mapToPayload(),
      mergeMap(({ id, data }) =>
        this.dealsService.updateDeal(id, data).pipe(
          mergeMap(deal => [
            editDealSuccess({ deal }),
            loadDealDetails({ code: deal.code }),
            showSuccessMessage({})
          ]),
          mapErrorToAction(editDealFail)
        )
      )
    )
  );

  deleteDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDeal),
      mapToPayloadProperty('id'),
      mergeMap((id) =>
        this.service.deleteDeal(id).pipe(
          mergeMap(deal => [
            deleteDealSuccess({ deal }),
            showSuccessMessage({})
          ]),
          mapErrorToAction(deleteDealFail)
        )
      )
    )
  );
}
