import { Pagination } from "../../../shared/models/utils/pagination.model";

export interface GenericList<T> {
  items?: T[];
  query?: string;
  pagination?: Pagination;
}

export function DefaultGenericList<T>(): GenericList<T> {
  return {
    items: [],
    query: '',
    pagination: {
      current: 0,
      pagesCount: 0,
      pageSize: 8,
      totalItems: 0
    }
  };
}