import { Injectable } from '@angular/core';
import * as alertify from 'alertifyjs';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor() {
    alertify.defaults = {
      ...alertify.defaults,
      notifier: {
        delay: 3,
        position: 'top-right'
      }
    };
  }

  confirm(message: string, okCallback: () => any) {
    alertify.confirm(message, (e: any) => {
      if (e) {
        okCallback();
      } else {
      }
    });
  }

  success(message?: string) {
    alertify.success(message || 'Datos guardados correctamente');
  }

  error(message?: string) {
    alertify.error(message || 'Ocurrió un error inesperado');
  }

  warning(message: string) {
    alertify.warning(message);
  }

  message(message: string) {
    alertify.message(message);
  }
}
