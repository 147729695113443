import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { DealOperationsService } from "../../shared/services/deal-operations.service";
import { mapErrorToAction, mapToPayload, mapToPayloadProperty } from "../shared/utils/operators";
import { logout } from "./auth.actions";
import { DealsService } from "../../shared/services/deals.service";
import { showSuccessMessage } from "../messages/messages.actions";
import { clearDeals } from "../deals";
import { clearInvestors } from "../investors";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: DealOperationsService,
    private dealsService: DealsService
  ) {}

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      mergeMap(() => [
        clearDeals(),
        clearInvestors()
      ])
    )
  );
}
