import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { LogsService } from "../../shared/services/logs.service";
import { showErrorMessage, showSuccessMessage } from "./messages.actions";
import { mapToPayloadProperty } from "../shared/utils/operators";
import { map, mergeMap } from "rxjs/operators";

@Injectable()
export class MessagesEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: LogsService
  ) {}

  showSuccessMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showSuccessMessage),
      mapToPayloadProperty('message'),
      map((message) => this.service.success(message))
    ), { dispatch: false }
  );

  showErrorMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showErrorMessage),
      mapToPayloadProperty('message'),
      map((message) => this.service.error(message))
    ), { dispatch: false }
  );

}
