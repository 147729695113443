import { NgModule } from "@angular/core";
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../../environments/environment";
import { DealsEffects } from "./deals/deals.effects";
import { dealsReducer } from "./deals/deals.reducer";
import { InvestorsEffects } from "./investors/investors.effects";
import { investorsReducer } from "./investors/investors.reducer";
import { BaseState } from "./store";
import { MessagesEffects } from "./messages/messages.effects";
import { portfoliosReducer } from "./portfolios/portfolios.reducer";
import { PortfoliosEffects } from "./portfolios/portfolios.effects";
import { authReducer } from "./auth/auth.reducer";
import { AuthEffects } from "./auth/auth.effects";

const baseReducers: ActionReducerMap<BaseState> = {
  deals: dealsReducer,
  portfolios: portfoliosReducer,
  investors: investorsReducer,
  auth: authReducer
};

const baseEffects = [DealsEffects, PortfoliosEffects, InvestorsEffects, MessagesEffects, AuthEffects];

@NgModule({
  imports: [
    StoreModule.forRoot<BaseState>(baseReducers),
    EffectsModule.forRoot(baseEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
})
export class BaseStoreModule {}
