import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { DealOperationsService } from "../../shared/services/deal-operations.service";
import { mapErrorToAction, mapToPayloadProperty } from "../shared/utils/operators";
import {
  initInvestors,
  loadInvestorCompany,
  loadInvestorCompanyFail,
  loadInvestorCompanyStats,
  loadInvestorCompanyStatsFail,
  loadInvestorCompanyStatsSuccess,
  loadInvestorCompanySuccess,
  loadInvestors,
  loadInvestorsFail,
  loadInvestorsSuccess,
  loadInvestorUser,
  loadInvestorUserFail,
  loadInvestorUserSuccess,
  setInvestorItemActive,
  setInvestorItemActiveFail,
  setInvestorItemActiveSuccess,
  setInvestorItemBusinessConsultant,
  setInvestorItemBusinessConsultantFail,
  setInvestorItemBusinessConsultantSuccess
} from './investors.actions';
import { getInvestorCompanyStats, getInvestorsCompanyStatsList, getInvestorsInitialized, getInvestorsList } from "./investors.selectors";
import { showSuccessMessage } from "../messages/messages.actions";

@Injectable()
export class InvestorsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: DealOperationsService
  ) {}

  initInvestors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initInvestors),
      withLatestFrom(this.store.pipe(select(getInvestorsInitialized))),
      filter(([_, initialized]) => !initialized),
      map(() => loadInvestors())
    )
  );

  loadInvestors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInvestors),
      mergeMap(() =>
        this.service.getInvestors().pipe(
          map(list => loadInvestorsSuccess({ list })),
          mapErrorToAction(loadInvestorsFail)
        )
      )
    )
  );

  loadInvestorCompanyStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInvestorCompanyStats),
      mapToPayloadProperty('id'),
      withLatestFrom(this.store.pipe(select(getInvestorsCompanyStatsList))),
      filter(([id, list]) => !list.find(i => i.companyId == id)),
      map(([id, _]) => id),
      mergeMap(id =>
        this.service.getCompanyInvestorStats(id).pipe(
          map(stats => loadInvestorCompanyStatsSuccess({ stats })),
          mapErrorToAction(loadInvestorCompanyStatsFail)
        )
      )
    )
  );

  setInvestorItemActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setInvestorItemActive),
      mergeMap(({ payload }) =>
        this.service.updateInvestorCompany({ id: payload.id, isActive: payload.isActive }).pipe(
          mergeMap(() => [
            setInvestorItemActiveSuccess(payload),
            showSuccessMessage({})
          ]),
          mapErrorToAction(setInvestorItemActiveFail)
        )
      )
    )
  );

  setInvestorItemBusinessConsultant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setInvestorItemBusinessConsultant),
      mergeMap(({ payload }) =>
        this.service.updateInvestorCompany({ ...payload.dto }).pipe(
          mergeMap(company => [
            setInvestorItemBusinessConsultantSuccess({company}),
            showSuccessMessage({})
          ]),
          mapErrorToAction(setInvestorItemBusinessConsultantFail)
        )
      )
    )
  );

  loadInvestorCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInvestorCompany),
      mapToPayloadProperty('id'),
      mergeMap((id) =>
        this.service.getInvestorCompanyDetails(id).pipe(
          map(company => loadInvestorCompanySuccess({ company })),
          mapErrorToAction(loadInvestorCompanyFail)
        )
      )
    )
  );

  loadInvestorUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInvestorUser),
      mapToPayloadProperty('id'),
      mergeMap((id) =>
        this.service.getInvestorUserDetails(id).pipe(
          map(user => loadInvestorUserSuccess({ user })),
          mapErrorToAction(loadInvestorUserFail)
        )
      )
    )
  );
}
