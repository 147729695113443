import {Injectable} from '@angular/core';
import {BaseService} from './_base.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DealOperation, DealOperationType} from '../models/deal-operation.model';
import {DealOperationQuestion} from '../models/deal-operation-question.model';
import {DealOperationActivity} from '../models/deal-operation-activity.model';
import {Contributor} from '../models/contributor.model';
import {User} from '../models/user.model';
import {Company} from '../models/company.model';
import {DealOperationNotification} from '../models/deal-operation-notification.model';
import {Deal} from '../models/deal.model';
import {Property} from '../models/property.model';
import {DealOperationNDAFile} from '../models/deal-operation-nda-file.model';
import {CompanyInvestorDto, CompanyInvestorStatsDto} from '../models/dtos/company-investor.dto';
import {DealOperationFile} from '../models/deal-operation-file.model';
import {PDFRequestDto} from '../components/deals/deal-building-center-offer-form/dtos/pdf-request.dto';
import {delay, shareReplay} from 'rxjs/operators';

export interface GetDealOperationsDto {
  dealId?: number;
  propertyId?: number;
  type?: DealOperationType;
}

export interface DealOperationMetricsDto {
  activity: {
    visits: number;
    offers: number;
    questions: number;
  };
  conversion: {
    invitations: number;
    access: number;
    questions: number;
    visits: number;
    offers: number;
  };
  offersStatus: {
    pending: number;
    closed: number;
  };
  questionsStatus: {
    pending: number;
    closed: number;
  };
}

export interface DealPropertiesOperationMetricsDto {
  propertyId: number;
  accesses: number;
  questions: number;
  visits: number;
  offers: number;
}

export interface DealPropertiesOperationMetricsDetailsDto {
  accesses: {
    company: Company;
    count: number;
  }[];
  questions: {
    company: Company;
    count: number;
  }[];
  visits: {
    company: Company;
    count: number;
  }[];
  offers: {
    company: Company;
    count: number;
  }[];
}

export interface MatchInvestorsDto {
  price: number;
  type: string;
  subtype: string;
  province: string;
  state: string;
  clients: string[];
}

export interface MatchInvestorsResultDto {
  matching: number;
  company: Company;
}

export interface ImportInvestorsDto {
  companyVatNumber: string;
  companyName: string;
  companyOperationsType: string;
  companyOperationsInterest: string;
  companyType: string;
  companyOperationsSegment: string;
  companyIsActive: boolean;
  userRole: string;
  userFirstName: string;
  userLastName: string;
  userDocumentNumber: string;
  userJobPhone: string;
  userEmail: string;
  userCompanyAddress: string;
  userJobTitle: string;
}

export interface ImportInvestorsV2Dto {
  firstName: string;
  email: string;
}

export interface SendInvitationsDto {
  userId?: number;
  companyId?: number;
}

export interface GetInvestorsStatsDto {
  userId?: number;
  companyId?: number;
  since?: string;
  until?: string;
}

export interface GetInvestorStatsResultDto {
  accessesProperties: {
    deal: Deal;
    property: Property;
    count: number;
  }[];
  offersProperties: {
    deal: Deal;
    property: Property;
    count: number;
    price: number;
  }[];
}

export interface GetInvestorSignDatesResultDto {
  user: User;
  ndaFiles: DealOperationNDAFile[];
}

export interface ResendInvitationsResponse {
  invitations: number;
  totalContributors: number;
}

@Injectable({
  providedIn: 'root'
})
export class DealOperationsService extends BaseService {

  private endpoint = `deal-operations`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  getDeals() {
    return this.httpGet<Deal[]>(`${this.endpoint}/deals`);
  }

  getPortfolios() {
    return this.httpGet<Deal[]>(`${this.endpoint}/deals/portfolios`);
  }

  getDealsMetrics(ids: number[]): Observable<Blob> {
    return this.http.post(
      `${this.apiUrl}${this.endpoint}/deals/metrics`, { ids }, {...this.getHeaders(), responseType: 'blob'}
    );
  }

  getPortfolioMetrics(ids: number[]): Observable<Blob> {
    return this.http.post(
      `${this.apiUrl}${this.endpoint}/deals/portfolios/metrics/v2`, { ids }, {...this.getHeaders(), responseType: 'blob'}
    );
  }

  getDealDetails(code: string, propertyId?: number) {
    return this.httpGet<Deal>(`${this.endpoint}/deals/${code}${propertyId ? ('?propertyId=' + propertyId) : ''}`);
  }

  publishDeal(id: number) {
    return this.httpPost(`${this.endpoint}/deals/${id}/publish`, {});
  }

  deleteDeal(id: number) {
    return this.httpDelete<Deal>(`${this.endpoint}/deals/${id}`);
  }

  rejectProperty(id: number, reason: string) {
    return this.httpPost(`${this.endpoint}/deals/properties/${id}/reject`, { reason });
  }

  updatePropertyManager(propertyId: number, dto) {
    return this.httpPut(`${this.endpoint}/deals/properties/${propertyId}/managers`, dto);
  }

  updateInvestorContributor(id: number, dto) {
    return this.httpPut<Contributor>(`${this.endpoint}/deals/investors/${id}`, dto);
  }

  addDealNDAFile(dto) {
    return this.httpPost(`${this.endpoint}/nda-files`, dto);
  }


  getOperations(dto: GetDealOperationsDto = {}): Observable<DealOperation[]> {
    return this.httpGet<DealOperation[]>(`${this.endpoint}`, dto);
  }

  createOperation(data: any) {
    return this.httpPost<DealOperation>(`${this.endpoint}`, data);
  }

  getDraftOperation(dealId: number) {
    return this.httpGet<DealOperation>(`${this.endpoint}/draft?dealId=${dealId}`);
  }

  createDraftOperationFile(data: any) {
    return this.httpPost<DealOperationFile>(`${this.endpoint}/draft/files`, data);
  }

  updateOfferDocFile(data: any) {
    return this.httpPost<DealOperationFile>(`${this.endpoint}/offer/files`, data);
  }

  updateDraftOperation(id: number, data: any) {
    return this.httpPut<DealOperation>(`${this.endpoint}/draft/${id}`, data);
  }

  updateOperationPrice(id: number, data: any) {
    return this.httpPut<DealOperation>(`${this.endpoint}/price/${id}`, data);
  }

  updateOfferForm(id: number, data: any) {
    return this.httpPut<DealOperation>(`${this.endpoint}/offer/${id}/form`, data);
  }

  deleteDraftOperation(id: number) {
    return this.httpDelete<DealOperation>(`${this.endpoint}/draft/${id}`);
  }

  getPDFOffer(id: number, dto: PDFRequestDto): Observable<Blob> {
    const url = `${this.apiUrl}${this.endpoint}/offers/pdf/${id}`;
    return this.http.post(url, dto, {...this.getHeaders(), responseType: 'blob'});
  }

  getPDFOfferLegacy(id: number, data: string): Observable<Blob> {
    const url = `${this.apiUrl}${this.endpoint}/offers/${id}`;
    return this.http.put(url, data, {...this.getHeaders(), responseType: 'blob'});
  }

  sendREMOffer(id: number): Observable<DealOperation> {
    const url = `${this.endpoint}/offers/rem/${id}`;
    return this.httpPost<DealOperation>(url);
  }

  sendPBCOffer(id: number, offerCode: number): Observable<any> {
    const url = `${this.endpoint}/offers/pbc/${id}?offer-code=${offerCode}`;
    return this.httpPost<any>(url);
  }

  getOperationSubmittings(id: number) {
    return this.httpGet<{ id: number, dateSent: string }[]>(`${this.endpoint}/${id}/submittings`);
  }

  submitOperation(id: number, dto: any) {
    return this.httpPost(`${this.endpoint}/${id}/submittings`, dto);
  }

  submitOperationPerson(id: number) {
    return this.httpPost<{ id: number }>(`${this.endpoint}/${id}/submit/person`, {});
  }

  submitOperationContainer(id: number, code: string, personId: number) {
    return this.httpPost<{ id: number }>(`${this.endpoint}/${id}/submit/container`, { code, personId });
  }

  submitOperationDocument(id: number, file: File, code: string, personId: number, requiredFileId: number) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<{ success: boolean, result: any }>(`${this.apiUrl}${this.endpoint}/${id}/submit/document?code=${code}&personId=${personId}&requiredFileId=${requiredFileId}`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem(this.TOKEN_NAME)}`
        }
      });
  }

  getOperationFile(id: number) {
    return this.httpGet<DealOperationFile>(`${this.endpoint}/operation-files/${id}`);
  }


  getOperationActivities(operationId: number) {
    return this.httpGet<DealOperationActivity[]>(`${this.endpoint}/${operationId}/activities`);
  }

  createOperationActivity(operationId: number, data: any) {
    return this.httpPost<DealOperationActivity>(`${this.endpoint}/${operationId}/activities`, data);
  }



  getQuestions(dealId: number, propertyId: number): Observable<DealOperationQuestion[]> {
    return this.httpGet<DealOperationQuestion[]>(`${this.endpoint}/questions?dealId=${dealId}${propertyId ? ('&propertyId=' + propertyId) : ''}`);
  }

  createQuestion(data: any) {
    return this.httpPost<DealOperationQuestion>(`${this.endpoint}/questions`, data);
  }

  updateQuestion(id: number, data: any) {
    return this.httpPut<DealOperationQuestion>(`${this.endpoint}/questions/${id}`, data);
  }

  getDealOperationMetrics(dto) {
    return this.httpGet<DealOperationMetricsDto>(`${this.endpoint}/metrics`, dto);
  }

  getDealPropertiesOperationMetrics(dealId: number) {
    return this.httpGet<DealPropertiesOperationMetricsDto[]>(`${this.endpoint}/properties/metrics?dealId=${dealId}`);
  }

  getDealPropertiesOperationMetricsDetails(propertyId: number) {
    return this.httpGet<DealPropertiesOperationMetricsDetailsDto>(`${this.endpoint}/properties/${propertyId}/metrics`);
  }

  getDealInvestors(dealId: number) {
    return this.httpGet<Contributor[]>(`${this.endpoint}/investors?dealId=${dealId}`);
  }

  getDealCollaborators(dealId: number) {
    return this.httpGet<Contributor[]>(`${this.endpoint}/investors/collaborators?dealId=${dealId}`);
  }



  getInvestors() {
    return this.httpGet<CompanyInvestorDto[]>(`${this.endpoint}/investors`);
  }

  getCompanyInvestorStats(id: number) {
    return this.httpGet<CompanyInvestorStatsDto>(`${this.endpoint}/investors/${id}/stats`);
  }

  getAllInvestorUsers() {
    return this.httpGet<User[]>(`${this.endpoint}/investors/details`);
  }

  findInvestors(dto: { query?: string, excludeDealId?: number, excludeCompanyIds?: number[], clients?: string[] }) {
    return this.httpPost<Company[]>(`${this.endpoint}/investors/search`, dto);
  }

  matchInvestors(dto: MatchInvestorsDto[]) {
    return this.httpPost<MatchInvestorsResultDto[]>(`${this.endpoint}/investors/match`, dto);
  }

  addInvestorToDeal(companyId: number, dealId: number) {
    return this.httpPut<Company[]>(`${this.endpoint}/investors`, { companyId, dealId });
  }

  addInvestorsToDeal(companyIds: number[], dealId: number) {
    return this.httpPut<Company[]>(`${this.endpoint}/investors`, { companyIds, dealId });
  }

  inviteInvestor(data: any) {
    return this.httpPost<Company[]>(`${this.endpoint}/investors`, data);
  }

  getInvestorUserDetails(id: number) {
    return this.httpGet<User>(`${this.endpoint}/investors/users/${id}`);
  }

   createInvestorUser(data: any) {
    return this.httpPost<User>(`${this.endpoint}/investors/user`, data);
   }

  updateInvestorUser(data: any) {
    return this.httpPut<User>(`${this.endpoint}/investors/user/${data.id}`, data);
  }

  uploadInvestorUserImage(id: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${this.apiUrl}${this.endpoint}/investors/user/${id}/image`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem(this.TOKEN_NAME)}`
        }
      });
  }

  getInvestorCompanyDetails(id: number) {
    return this.httpGet<Company>(`${this.endpoint}/investors/company/${id}`);
  }

  updateInvestorCompany(data: any) {
    return this.httpPut<Company>(`${this.endpoint}/investors/company/${data.id}`, data);
  }

  uploadInvestorCompanyImage(id: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${this.apiUrl}${this.endpoint}/investors/company/${id}/image`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem(this.TOKEN_NAME)}`
        }
      });
  }

  updateInvestorCompanyFinancialReport(id: number) {
    return this.httpPost<Company>(`${this.endpoint}/investors/company/${id}/financial-report`, {});
  }

  getInvestorAssessor() {
    return this.httpGet<User[]>(`${this.endpoint}/investors/assessors`);
  }

  inviteInvestorAssessor(data: any) {
    return this.httpPost<Company[]>(`${this.endpoint}/investors/assessors`, data);
  }

  importInvestors(data: ImportInvestorsDto[], dealId?: number) {
    return this.httpPost(`${this.endpoint}/investors/import${dealId ? `?dealId=${dealId}` : ''}`, data);
  }

  importInvestorsV2(data: ImportInvestorsV2Dto[], dealId?: number): Observable<{ success: number, failEmails: string[] }> {
    return this.httpPost< {success: number, failEmails: string[] }>(`${this.endpoint}/investors/import/v2?dealId=${dealId}`, data);
  }

  sendInvitations(dto?: SendInvitationsDto) {
    return this.httpPost(`${this.endpoint}/investors/invitations`, dto || {});
  }

  resendInvitations(dealId: number) {
    return this.httpPost<ResendInvitationsResponse>(`${this.endpoint}/investors/resend-invitations?dealId=${dealId}`)
      .pipe(shareReplay());
  }

  getInvestorsStats(dto: GetInvestorsStatsDto) {
    return this.httpGet<GetInvestorStatsResultDto>(`${this.endpoint}/investors/stats`, dto);
  }

  getInvestorsSignDates(dto: GetInvestorsStatsDto) {
    return this.httpGet<GetInvestorSignDatesResultDto[]>(`${this.endpoint}/investors/sign-dates`, dto);
  }


  getNotifications() {
    return this.httpGet<DealOperationNotification[]>(`${this.endpoint}/notifications`);
  }

  readNotification(id: number) {
    return this.httpPut<DealOperationNotification>(`${this.endpoint}/notifications/${id}`, {});
  }

  deleteNotification(id: number) {
    return this.httpDelete<DealOperationNotification>(`${this.endpoint}/notifications/${id}`);
  }
}
