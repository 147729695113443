import { createAction } from "@ngrx/store";
import { Deal } from "../../shared/models/deal.model";
import { httpError, payload } from "../shared/utils/ngrx-creators";

export const initDeals = createAction('[Deals] Init Deals');

export const loadDeals = createAction('[Deals] Load Deals');

export const loadDealsFail = createAction('[Deals] Load Deals Fail');

export const loadDealsSuccess = createAction(
  '[Deals] Load Deals Success',
  payload<{ deals: Deal[] }>()
);

export const setDealsQuery = createAction(
  '[Deals] Set Deals Query',
  payload<{ query: string }>()
);

export const loadDealDetails = createAction(
  '[Deals] Load Deal Details',
  payload<{ code: string }>()
);

export const loadDealDetailsFail = createAction('[Deals] Load Deal Details Fail', httpError());

export const loadDealDetailsSuccess = createAction(
  '[Deals] Load Deal Details Success',
  payload<{ deal: Deal }>()
);

export const editDeal = createAction(
  '[Deals] Edit Deal',
  payload<{ id: number, data: any }>()
);

export const editDealFail = createAction('[Deals] Edit Deal Fail', httpError());

export const editDealSuccess = createAction(
  '[Deals] Edit Deal Success',
  payload<{ deal: Deal }>()
);

export const deleteDeal = createAction(
  '[Deals] Delete Deal',
  payload<{ id: number }>()
);

export const deleteDealFail = createAction('[Deals] Delete Deal Fail', httpError());

export const deleteDealSuccess = createAction(
  '[Deals] Delete Deal Success',
  payload<{ deal: Deal }>()
);

export const clearDeals = createAction('[Deals] Clear Deals');
